/* HK Gorkest */

@font-face {
	font-family: 'HK Grotesk';
	src: url('../fonts/HKGrotesk-Regular.ttf');
	font-weight: 300;
	font-display: swap;
	font-style: normal;
}

@font-face {
	font-family: 'HK Grotesk';
	src: url('../fonts/HKGrotesk-Medium.ttf');
	font-weight: 400;
	font-display: swap;
	font-style: normal;
}

@font-face {
	font-family: 'HK Grotesk';
	src: url('../fonts/HKGrotesk-Medium.ttf');
	font-weight: 500;
	font-display: swap;
	font-style: normal;
}

@font-face {
	font-family: 'HK Grotesk';
	src: url('../fonts/HKGrotesk-SemiBold.ttf');
	font-weight: 600;
	font-display: swap;
	font-style: normal;
}

@font-face {
	font-family: 'HK Grotesk';
	src: url('../fonts/HKGrotesk-Bold.ttf');
	font-weight: 700;
	font-display: swap;
	font-style: normal;
}
