@charset "UTF-8";
@import url('https://fonts.googleapis.com/css?family=Roboto+Mono:500&display=swap');
@import './app.css';
@import './font.css';

:root {
	--theme-primary: #4868f8;
	--white: #fafafa;
	--accent: #062e64;
	--alert-success: #438875;
	--alert-danger: #da3451;
	--sidebar-width: 260px;
	--gray: #8f9bb3;
	--font-primary: 'HK Grotesk';
	--font-secondary: 'Roboto Mono';
}

::selection {
	background: var(--theme-primary);
	color: var(--white);
	/* WebKit/Blink Browsers */
}

::-moz-selection {
	background: var(--theme-primary);
	color: var(--white);
	/* Gecko Browsers */
}

::-webkit-input-placeholder {
	color: var(--gray);
	opacity: 1;
}

:-moz-placeholder {
	color: var(--gray);
	opacity: 1;
}

::-moz-placeholder {
	color: var(--gray);
	opacity: 1;
}

:-ms-input-placeholder {
	color: var(--gray);
	opacity: 1;
}

body {
	font-family: var(--font-primary), -apple-system, BlinkMacSystemFont,
		'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

* {
	box-sizing: border-box;
	padding: 0;
	margin: 0;
}

html {
	font-display: fallback;
	box-sizing: border-box;
	scroll-behavior: smooth;
	image-rendering: crisp-edges;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.btn-developp {
	background: var(--theme-primary);
	box-shadow: none;
	border-radius: 6px;
	border: none;
}

.btn-developp:hover {
	opacity: 0.8;
	color: #fff;
}

@media screen and (max-width: 935px) {
	:root {
		--sidebar-width: 0px !important;
	}
}

.Toastify__toast--error {
	background: #f54533;
	border-radius: 4px;
}

.Toastify__toast-body {
	margin: auto 0;
	flex: 1 1;
	font-size: 14px;
	font-family: var(--font-family-sans-serif) !important;
}

.Toastify__toast {
	position: relative;
	min-height: 64px;
	margin-bottom: 1rem;
	padding: 12px 13px !important;
	border-radius: 4px !important;
	box-shadow: none !important;
	display: flex;
	justify-content: space-between;
	max-height: 800px;
	overflow: hidden;
	font-family: sans-serif;
	cursor: pointer;
	direction: ltr;
}

.Toastify__close-button {
	color: #fff;
	font-weight: 100 !important;
}

.modal-header {
	padding: 0 !important;
	border: none !important;
}

.modal-content {
	padding: 2.1rem;
	border: none !important;
	box-shadow: none !important;
}

.modal-body {
	padding: 1rem 0 !important;
}

.dropdown-item {
	font-family: var(--font-family-sans-serif);
	display: block;
	width: 100%;
	padding: 0.65rem 1.3rem !important;
	clear: both;
	font-weight: 400 !important;
	color: #3e4857 !important;
	font-size: 15px !important;
	text-align: inherit;
	white-space: nowrap;
	background-color: transparent;
	border: 0;
}

#blah2 {
	width: 100% !important;
	height: 100% !important;
}

.grid {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));
	grid-template-rows: 1fr;
	grid-column-gap: 22px;
	grid-row-gap: 22px;
}

.dropdown-menu {
	background: #ffffff;
	box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
		0px 4px 8px rgba(0, 0, 0, 0.04);
	border-radius: 6px;
	border: none !important;
	animation: fadeIns;
	animation-duration: 0.5s;
	user-select: none;
	animation-iteration-count: 1;
}

@keyframes fadeIns {
	from {
		opacity: 0.2;
	}

	to {
		opacity: 1;
	}
}

.fadeIn {
	animation: fadeer;
	animation-fill-mode: both;
	animation-duration: 0.5s;
}

@keyframes fadeer {
	0% {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}
